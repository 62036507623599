import React, { useContext, useState, useEffect } from "react";
import { usePrivy } from "@privy-io/react-auth";
// import { Permission } from "@ansiblelabsxyz/beam-common/prisma";
import API from "../api/api";
import Loader from "../components/Loader";

export interface CurrentUser {
  id: string | null;
  type: string;
  email: string;
  // permissions: Permission[];
  permissions: any[];
  assignedAccounts: any[];
  selectedAccount: any;
}

const AuthContext = React.createContext<{
  currentUser: CurrentUser;
  updateUser: (any) => any;
}>({
  currentUser: {
    id: null,
    type: "",
    email: "",
    // permissions: [] as Permission[],
    permissions: [] as any[],
    assignedAccounts: [],
    selectedAccount: null,
  },
  updateUser: (user) => {},
});
export function useAuth() {
  return useContext(AuthContext);
}
export function AuthProvider({ children }: any) {
  const [loading, setLoading] = useState<boolean>(true);
  const { ready, authenticated } = usePrivy();
  const [currentUser, setCurrentUser] = useState<CurrentUser>(null);

  useEffect(() => {
    if (ready && authenticated) {
      getUser();
    } else if (ready && !authenticated) {
      setLoading(false);
      if (!["/login", "/register"].includes(window.location.pathname)) {
        window.location.href = "/login";
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ready, authenticated]);

  // Update the user
  const updateUser = (user) => {
    setCurrentUser({ ...currentUser, ...user });
  };

  // make a get request on the user
  const getUser = async () => {
    const userData = await API.post("/business/users");
    const user = userData.data;
    const { data: assignedAccounts } = await API.get(
      `business/users/${user.id}/accounts`,
    );
    const { data: permissions } = await API.get(
      `business/users/${user.id}/permissions`,
    );

    setCurrentUser({
      ...currentUser,
      ...user,
      permissions,
      assignedAccounts,
      selectedAccount: assignedAccounts[0],
    });
    setLoading(false);

    if (
      window.location.pathname === "/login" ||
      window.location.pathname === "/register"
    ) {
      window.location.href = "/accounts";
    }
  };

  // Export the value for other functions to call it.
  const value = {
    currentUser,
    updateUser,
  };

  return (
    <AuthContext.Provider value={value}>
      {!ready && <Loader />}
      {ready && !loading && children}
    </AuthContext.Provider>
  );
}
