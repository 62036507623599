import { useState, useEffect } from "react";
import axios from "axios";

import { RAPID_API_KEY } from "../../config";
import {
  usBankAccountNumberInputRegex,
  usBankRoutingNumberInputRegex,
  usBankAccountNumber,
  abaRoutingNumber,
} from "../../utils/regex";

type Props = {
  loading: boolean;
  submitACHPaymentInfo: (data: ACHPaymentInfo) => void;
  onClose: () => void;
  closeable?: boolean;
};

type BankAccountType = "checking" | "savings";

export type ACHPaymentInfo = {
  accountType: BankAccountType;
  accountNumber: string;
  accountNumber2: string;
  routingNumber: string;
  description?: string;
};

const NO_BANK_FOUND_TEXT = "Unknown Bank";

const getRoutingNumBank = async (value: string) => {
  const options = {
    method: "GET",
    url: `https://routing-number-bank-lookup.p.rapidapi.com/api/v1/${value}`,
    params: {
      format: "json",
      paymentType: "ach",
    },
    headers: {
      "X-RapidAPI-Key": RAPID_API_KEY,
      "X-RapidAPI-Host": "routing-number-bank-lookup.p.rapidapi.com",
    },
  };

  try {
    const response = await axios.request(options);
    return response?.data[0]?.data?.name ?? NO_BANK_FOUND_TEXT;
  } catch (error) {
    console.error(error);
    return NO_BANK_FOUND_TEXT;
  }
};

export default function ACHPaymentInfoForm({
  submitACHPaymentInfo,
  loading,
  onClose,
  closeable = true,
}: Props) {
  const [bankDetails, setBankDetails] = useState<ACHPaymentInfo>({
    accountType: "checking",
    accountNumber: "",
    accountNumber2: "",
    routingNumber: "",
    description: "",
  });
  const [bank, setBank] = useState("") as any;
  useEffect(() => {
    async function getBank() {
      const routingBankName = await getRoutingNumBank(
        bankDetails.routingNumber,
      );
      setBank(routingBankName);
    }
    // routing number is 9 digits
    if (bankDetails.routingNumber?.length === 9) {
      getBank();
    }
  }, [bankDetails.routingNumber]);

  const disableSubmit = () => {
    return (
      bankDetails.accountNumber !== bankDetails.accountNumber2 ||
      !RegExp(usBankAccountNumber).test(bankDetails.accountNumber) ||
      !RegExp(abaRoutingNumber).test(bankDetails.routingNumber)
    );
  };

  const handleRoutingNumberChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = (e.target as HTMLInputElement).value;

    if (RegExp(usBankRoutingNumberInputRegex).test(value)) {
      setBankDetails({
        ...bankDetails,
        routingNumber: value,
      });
    }
  };

  const handleAccountNumberChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = (e.target as HTMLInputElement).value;

    if (RegExp(usBankAccountNumberInputRegex).test(value)) {
      setBankDetails({
        ...bankDetails,
        accountNumber: e.target.value,
      });
    }
  };

  const handleAccountNumber2Change = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = (e.target as HTMLInputElement).value;

    if (RegExp(usBankAccountNumberInputRegex).test(value)) {
      setBankDetails({
        ...bankDetails,
        accountNumber2: value,
      });
    }
  };

  return (
    <div
      onKeyDown={(e) => {
        if (e.key === "Enter" && !disableSubmit()) {
          submitACHPaymentInfo(bankDetails);
        }
      }}
    >
      <div className="py-2">
        <label
          htmlFor="account-description"
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          Account Description
        </label>
        <div className="mt-2">
          <input
            type="text"
            name="account-description"
            id="account-description"
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-lavender-700 sm:text-sm sm:leading-6"
            placeholder="Main Business Account - 123 First Bank"
            onChange={(e) => {
              setBankDetails({
                ...bankDetails,
                description: e.target.value,
              });
            }}
            onCopy={(e) => e.preventDefault()}
            onPaste={(e) => {
              e.preventDefault();
              setBankDetails(bankDetails);
            }}
            value={bankDetails?.description ?? ""}
          />
        </div>
      </div>
      <div className="pb-2">
        <label
          htmlFor="account-type"
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          Account Type
        </label>
        <select
          id="account-type"
          name="account-type"
          className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-lavender-700 sm:text-sm sm:leading-6"
          defaultValue="checking"
          onChange={(e) => {
            setBankDetails({
              ...bankDetails,
              accountType: e.target.value as BankAccountType,
            });
          }}
          value={bankDetails.accountType}
        >
          <option>Checking</option>
          <option>Savings</option>
        </select>
      </div>
      <div className="py-2">
        <label
          htmlFor="routing-number"
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          Routing Number
        </label>
        <div className="mt-1">
          <input
            type="text"
            name="routing-number"
            id="routing-number"
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-lavender-700 sm:text-sm sm:leading-6"
            placeholder="122105278"
            onChange={handleRoutingNumberChange}
            value={bankDetails?.routingNumber ?? ""}
          />
          <p className="mt-2 text-sm text-gray-500" id="routing-bank">
            {bankDetails?.routingNumber?.length >= 9 && bank ? bank : null}
          </p>
        </div>
      </div>
      <div className="py-2">
        <label
          htmlFor="account-number"
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          Account Number
        </label>
        <div className="mt-2">
          <input
            type="text"
            name="account-number"
            id="account-number"
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-lavender-700 sm:text-sm sm:leading-6"
            placeholder="122105278"
            onChange={handleAccountNumberChange}
            value={bankDetails?.accountNumber ?? ""}
          />
        </div>
      </div>
      <div className="py-2">
        <label
          htmlFor="account-number2"
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          Re-enter Account Number
        </label>
        <div className="mt-2">
          <input
            type="text"
            name="account-number2"
            id="account-number2"
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-lavender-700 sm:text-sm sm:leading-6"
            placeholder="122105278"
            onChange={handleAccountNumber2Change}
            onCopy={(e) => e.preventDefault()}
            onPaste={(e) => {
              e.preventDefault();
              setBankDetails(bankDetails);
            }}
            value={bankDetails?.accountNumber2 ?? ""}
          />
        </div>
      </div>
      <div className="mt-5 sm:mt-6 w-full self-end gap-2 flex flex-row">
        {closeable ? (
          <button
            type="button"
            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
            onClick={() => onClose()}
          >
            Cancel
          </button>
        ) : null}

        <button
          type="submit"
          className="inline-flex w-full justify-center rounded-md bg-lavender-400 hover:bg-lavender-600 px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 sm:col-start-2 disabled:bg-lavender-200 disabled:text-lavender-700 disabled:opacity-50"
          onClick={() => submitACHPaymentInfo(bankDetails)}
          disabled={disableSubmit()}
        >
          {loading ? (
            <svg
              className="mr-3 h-5 w-5 animate-spin text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          ) : null}
          Submit
        </button>
      </div>
    </div>
  );
}
