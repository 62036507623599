/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";

import API from "../../../api/api";
import OnboardingSummary from "./OnboardingSummary";
import OnboardingHeader from "./OnboardingHeader";
import NewOnboarding from "./NewOnboarding";
import Loader from "../../../components/Loader";
import { useAuth } from "../../../contexts/Auth";
import { useNavigate } from "react-router-dom";

export default function OnboardingDetails() {
  const user = useAuth();
  const navigate = useNavigate();
  const location = window.location.pathname.split("/")[2];
  const [companyName, setCompanyName] = useState<string | null>(null);
  const [onboardingStatus, setOnboardingStatus] = useState<string | null>(null);
  const [id, setId] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const url = `business/onboarding/${user.currentUser.selectedAccount.accountId}`;
      const { data } = await API.get(url);
      setId(user.currentUser.selectedAccount.accountId);
      if (data.kyc.status === "Approved") {
        navigate(`/wallets`);
      } else {
        setOnboardingStatus(data.kyc.status);
        setLoading(false);
      }
    };

    if (user.currentUser?.selectedAccount?.accountId) {
      getData();
    }

    // if selected id doesn't equal idf from url that update url
    if (user.currentUser?.selectedAccount?.accountId !== location) {
      // url structure is https://beam.wireless.money/accounts/f4a1cc03-88e1-471d-adad-03a9874d932f
      window.history.pushState(
        "",
        "",
        `/accounts/${user.currentUser?.selectedAccount?.accountId}`,
      );
    }
  }, [user.currentUser?.selectedAccount?.accountId]);

  return (
    <>
      {loading && <Loader />}
      {onboardingStatus !== "Approved" && !loading && (
        <>
          <OnboardingHeader companyName={companyName} location={location} />
          <NewOnboarding setCompanyName={setCompanyName} />
        </>
      )}

      {onboardingStatus === "Approved" && id && (
        <OnboardingSummary accountId={id} />
      )}
    </>
  );
}
