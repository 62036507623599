import { useEffect, useState } from "react";
import { format } from "date-fns";

import API from "../api/api";
import { useAuth } from "../contexts/Auth";
import TransactionDetails from "../components/TransactionDetails";
import { Transaction } from "../types";
import Loader from "../components/Loader";

export default function Transactions() {
  const [selectedTransaction, setSelectedTransaction] =
    useState<Transaction | null>(null);
  const auth = useAuth();
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [loading, setLoading] = useState(false);
  // const [downloading, setDownloading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const onClose = () => setSelectedTransaction(null);

  const getTransactionText = (transaction: Transaction) => {
    switch (transaction.type) {
      case "Payment":
        return "Transfer";
      case "Trade":
        return "Trade";
      case "Deposit":
        return "Deposit";
      default:
        return "Transaction";
    }
  };

  const getAmount = (transaction: Transaction) => {
    if (transaction.type === "Payment") {
      return `$${transaction.amount}`;
    } else if (transaction.type === "Trade" || transaction.type === "Deposit") {
      return `${transaction.amount} ${transaction.asset}`;
    }
  };

  useEffect(() => {
    async function fetchTransactions() {
      try {
        setLoading(true);
        const { data } = await API({
          url: `/business/users/transactions/${auth?.currentUser?.selectedAccount?.accountId}`,
          method: "GET",
        });
        if (data) {
          setTransactions(data);
        }
      } catch (error: any) {
        console.error(error);
        setError(`Error fetching transactions: ${error.response?.data?.error}`);
      } finally {
        setLoading(false);
      }
    }

    if (auth?.currentUser?.selectedAccount?.accountId) {
      fetchTransactions();
    }
  }, [auth?.currentUser?.selectedAccount?.accountId]);

  // const handleDownload = useCallback(async () => {
  //   if (!downloading) {
  //     try {
  //       setDownloading(true);
  //       const { data, headers } = await API.get(
  //         `${BACKEND_URL}/business/${auth?.currentUser?.selectedAccount?.accountId}/transactions/${auth.currentUser?.id}/csv`
  //       );
  //       downloadFile({
  //         data,
  //         mimeType: "text/csv",
  //         fileName:
  //           headers["content-disposition"]?.split("filename=")?.[1] ??
  //           "transactions.csv",
  //       });
  //     } catch (error: any) {
  //       setError(`Error download transactions: ${error.response?.data?.error}`);
  //     } finally {
  //       setDownloading(false);
  //     }
  //   }
  // }, [
  //   auth.currentUser?.id,
  //   downloading,
  //   auth?.currentUser?.selectedAccount?.accountId,
  // ]);

  // const handleErrorClose = () => {
  //   setError(null);
  // };
  useEffect(() => {
    if (error) console.error(error);
  }, [error]);

  return (
    <>
      <main className="bg-grey p-6">
        <div className="flex flex-row items-center mb-2">
          <h4 className="text-xl font-bold dark:text-black">Transactions</h4>
        </div>
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            {/* <p className="mt-2 text-sm text-gray-700">
              To request additional asset addresses, please contact{" "}
              <a href="mailto:support@ansiblelabs.xyz" className="underline">
                support@ansiblelabs.xyz
              </a>
            </p> */}
          </div>
        </div>
        {loading ? (
          <Loader />
        ) : (
          <div className="mt-8 flow-root">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="p-4 text-left text-sm font-semibold text-gray-900"
                        >
                          Date
                        </th>
                        <th
                          scope="col"
                          className="p-4 text-left text-sm font-semibold text-gray-900"
                        >
                          Type
                        </th>
                        <th
                          scope="col"
                          className="p-4 text-left text-sm font-semibold text-gray-900"
                        >
                          Amount
                        </th>
                        <th
                          scope="col"
                          className="p-4 text-left text-sm font-semibold text-gray-900"
                        >
                          Status
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {transactions.map((transaction) => (
                        <tr
                          key={transaction.id}
                          onClick={() => setSelectedTransaction(transaction)}
                          className="hover:bg-gray-100 cursor-pointer"
                        >
                          <td className="whitespace-nowrap p-4 text-sm font-medium text-gray-900">
                            {format(
                              new Date(transaction.createdAt),
                              "MMM dd, yyyy, h:mm a",
                            )}
                          </td>
                          <td className="whitespace-nowrap p-4 text-sm text-gray-500">
                            {getTransactionText(transaction)}
                          </td>
                          <td className="whitespace-nowrap p-4 text-sm text-gray-500">
                            {getAmount(transaction)}
                          </td>
                          <td className="whitespace-nowrap p-4 text-sm text-gray-500">
                            {transaction.status}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}
      </main>
      {selectedTransaction && (
        <TransactionDetails
          transaction={selectedTransaction}
          setClose={onClose}
        />
      )}
    </>
  );
}
