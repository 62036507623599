import React from "react";

const Statements: React.FC = () => {
  const [statements] = React.useState([]);
  return (
    <main className="bg-grey p-6">
      <div className="flex flex-row items-center mb-2">
        <h4 className="text-xl font-bold dark:text-black">Statements</h4>
      </div>
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <p className="mt-2 text-sm text-gray-700">
            If a statement is not available for the month you are looking for,
            please reach out to{" "}
            <a href="mailto:support@ansiblelabs.xyz" className="underline">
              support@ansiblelabs.xyz
            </a>
          </p>
        </div>
      </div>
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="p-4 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Report
                    </th>
                    <th
                      scope="col"
                      className="p-4 text-left text-sm font-semibold text-gray-900"
                    >
                      Description
                    </th>
                    <th
                      scope="col"
                      className="p-4 text-left text-sm font-semibold text-gray-900"
                    >
                      Period
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {statements.map((user) => (
                    <tr key={user.id}>
                      <td className="whitespace-nowrap p-4 text-sm font-medium text-gray-900 sm:pl-6">
                        {user.name}
                      </td>
                      <td className="whitespace-nowrap p-4 text-sm text-gray-500">
                        {user.email}
                      </td>
                      <td className="whitespace-nowrap p-4 text-sm text-gray-500">
                        {!!user?.authentication_id ? (
                          <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                            Active
                          </span>
                        ) : (
                          <span className="inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-700 ring-1 ring-inset ring-yellow-600/20">
                            Pending
                          </span>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Statements;
