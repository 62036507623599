import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import { AuthProvider } from "./contexts/Auth";
import Login from "./views/Login";
import Internal from "./views/Internal";
import Onboarding from "./views/Onboarding/Onboarding";
import OnboardingDetails from "./views/Onboarding/OnboardingDetails";
import Transactions from "./views/Transactions";
import ConnectedAccounts from "./views/ConnectedAccounts";
import UserManagement from "./views/UserManagement";
import Embedded from "./views/Embedded";
import Statements from "./views/Statements";
import Wallets from "./views/Wallets";
import Market from "./views/Market";
import ExternalEmbeddedOutlet from "./views/ExternalEmbeddedOutlet";
import { ToastProvider } from "./contexts/ToastContext";
import "./App.css";
import Api from "./views/Api/Api";
import Settings from "./views/Settings";

export function App() {
  const embeddedView = window.location.href.includes("external-embedded");
  return (
    <Router>
      {embeddedView && (
        <Routes>
          <Route
            path="/external-embedded"
            element={<ExternalEmbeddedOutlet />}
          />
        </Routes>
      )}
      {!embeddedView && (
        <ToastProvider>
          <AuthProvider>
            <Routes>
              <Route index path="/login" Component={Login} />
              <Route path="/" element={<Internal />}>
                <Route index path="/accounts" element={<Onboarding />} />
                <Route path="/transactions" element={<Transactions />} />
                <Route path="/balances" element={<Wallets />} />
                <Route path="/wallets" element={<Wallets />} />
                <Route path="/market" element={<Market />} />
                <Route
                  path="/connected-accounts"
                  element={<ConnectedAccounts />}
                />
                <Route path="/embedded" element={<Embedded />} />
                <Route path="/user-management" element={<UserManagement />} />
                <Route path="/statements" element={<Statements />} />
                <Route path="/settings" element={<Settings />} />
                <Route path="/api" element={<Api />} />
                <Route path="/accounts/:id" element={<OnboardingDetails />} />
                <Route path="/" element={<Navigate to="/accounts" />} />
              </Route>
            </Routes>
          </AuthProvider>
        </ToastProvider>
      )}
    </Router>
  );
}
