import Dialog from "./Dialog";

import { useToast } from "../contexts/ToastContext";
import { useState } from "react";
import API from "../api/api";
import { useAuth } from "../contexts/Auth";

const NewAPIIpAddress = ({
  open,
  setOpen,
  onSuccess,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onSuccess: (result) => void;
}) => {
  const auth = useAuth();
  const { showToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [selectedAccount] = useState(
    auth.currentUser?.selectedAccount?.accountId,
  );
  const [ipDetails, setIpDetails] = useState({
    description: "",
    ipAddress: "",
  });

  const disableSubmit = (): boolean => {
    return (
      ipDetails?.description?.length === 0 ||
      ipDetails?.ipAddress?.length === 0 ||
      loading
    );
  };

  const addIpAddress = async () => {
    try {
      setLoading(true);
      // business/:id/apiKeys
      const { data } = await API.post(
        `business/${selectedAccount}/ipAddresses`,
        ipDetails,
      );
      setLoading(false);
      showToast("IP Address added successfully");
      onSuccess(data);
      setOpen(false);
    } catch (error) {
      setLoading(false);
      showToast("Error adding IP Address");
    }
  };

  return (
    <Dialog open={open} setOpen={setOpen}>
      <div className="mb-2">
        <div className="flex justify-between items-center">
          <div className="flex-grow text-center">
            <p className="text-xl dark:text-black">Add a new Ip Address</p>
          </div>
        </div>
        <div className="flex flex-col items-start justify-center mt-6">
          <div className="py-2 w-full">
            <label
              htmlFor="account-description"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Description
            </label>
            <div className="mt-2">
              <input
                type="text"
                name="description"
                id="description"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-lavender-700 sm:text-sm sm:leading-6"
                placeholder=""
                onChange={(e) => {
                  setIpDetails({
                    ...ipDetails,
                    description: e.target.value,
                  });
                }}
                value={ipDetails?.description}
              />
            </div>
          </div>
          <div className="py-2 w-full">
            <label
              htmlFor="account-description"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Ip Address
            </label>
            <div className="mt-2 relative">
              <input
                type="text"
                name="key"
                id="key"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-lavender-700 sm:text-sm sm:leading-6"
                placeholder=""
                onChange={(e) => {
                  setIpDetails({
                    ...ipDetails,
                    ipAddress: e.target.value.trim(),
                  });
                }}
                value={ipDetails?.ipAddress}
              />
            </div>
          </div>

          <div className="mt-5 sm:mt-6 w-full self-end gap-2 flex flex-row">
            <button
              type="button"
              className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
              onClick={() => setOpen(false)}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="inline-flex w-full justify-center rounded-md bg-lavender-400 hover:bg-lavender-600 px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 sm:col-start-2 disabled:bg-lavender-200 disabled:text-lavender-700 disabled:opacity-50"
              onClick={addIpAddress}
              disabled={disableSubmit()}
            >
              {loading ? (
                <svg
                  className="mr-3 h-5 w-5 animate-spin text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              ) : null}
              Submit
            </button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default NewAPIIpAddress;
