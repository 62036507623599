import { useCallback, useEffect, useState } from "react";

import { ReactComponent as BitcoinIcon } from "../assets/bitcoin.svg";
import { ReactComponent as EthIcon } from "../assets/eth.svg";
import { ReactComponent as ArbitrumIcon } from "../assets/arbitrum.svg";
import { ReactComponent as PolygonIcon } from "../assets/polygon.svg";
import { ReactComponent as SolanaIcon } from "../assets/sol.svg";
import { ReactComponent as XRPIcon } from "../assets/xrp.svg";
import { ReactComponent as DOGEIcon } from "../assets/dogecoin.svg";
import { ReactComponent as StellarIcon } from "../assets/stellar.svg";
import { ReactComponent as AvalanceIcon } from "../assets/avax.svg";
import { ReactComponent as AlgorandIcon } from "../assets/algorand.svg";
import { ReactComponent as USDCIcon } from "../assets/usdc.svg";
import { ReactComponent as USDIcon } from "../assets/usd.svg";
import { ReactComponent as USDCSol } from "../assets/usdcSolana.svg";
import { ReactComponent as USDTIcon } from "../assets/usdt.svg";

import Loader from "../components/Loader";
import API from "../api/api";
import { useAuth } from "../contexts/Auth";

import { InformationCircleIcon } from "@heroicons/react/24/outline";
import CryptoDepositInstructions from "../components/CryptoDepositInstructions";
import { Tooltip } from "../components/Tooltip";
import FiatDepositInstructions from "../components/FiatDepositInstructions";

export default function Wallets() {
  const [loading, setLoading] = useState<boolean>(false);
  const [
    selectedCryptoDepositInstructions,
    setSelectedCryptoDepositInstructions,
  ] = useState<null | {}>(null);
  const [selectedFiatDepositInstructions, setSelectedFiatDepositInstructions] =
    useState<null | {}>(null);
  const auth = useAuth();

  const [cryptoDepositInstructions, setCryptoDepositInstructions] =
    useState<any>([]);
  const [fiatDepositInstructions, setFiatDepositInstructions] = useState<any>(
    [],
  );

  const getIcon = (asset: string, fortressNetworkId?: string) => {
    switch (asset) {
      case "BTC":
        return <BitcoinIcon height={25} />;
      case "ETH":
        return <EthIcon height={25} />;
      case "ARB":
        return <ArbitrumIcon height={25} />;
      case "MATIC":
        return <PolygonIcon height={25} />;
      case "SOL":
        return <SolanaIcon height={25} />;
      case "XRP":
        return <XRPIcon height={25} />;
      case "DOGE":
        return <DOGEIcon height={25} />;
      case "XLM":
        return <StellarIcon height={25} />;
      case "AVAX":
        return <AvalanceIcon height={25} />;
      case "ALGO":
        return <AlgorandIcon height={25} />;
      case "USD":
        return <USDIcon height={25} />;
      case "USDT":
        return <USDTIcon height={25} />;
      case "USDC":
        switch (fortressNetworkId) {
          case "solanaDevnet":
            return <USDCSol height={25} />;
          default:
            return <USDCIcon height={25} />;
        }
    }
  };

  const getDepositInstructions = useCallback(async () => {
    setLoading(true);
    const { data } = await API.get(
      `business/${auth?.currentUser?.selectedAccount?.accountId}/depositInstructions`,
    );
    const { crypto, fiat } = data;
    if (crypto) {
      setCryptoDepositInstructions(crypto);
    }
    if (fiat) {
      console.log(fiat);
      setFiatDepositInstructions(fiat);
    }

    setLoading(false);
  }, [auth?.currentUser?.selectedAccount?.accountId]);

  useEffect(() => {
    if (auth?.currentUser?.selectedAccount?.accountId) {
      getDepositInstructions();
    }
  }, [auth?.currentUser?.selectedAccount?.accountId, getDepositInstructions]);

  const getName = (depositInstruction: any) => {
    if (depositInstruction.asset.symbol === "USDC") {
      return `${depositInstruction?.asset?.symbol} on ${depositInstruction.asset?.network?.name}`;
    } else if (depositInstruction.asset.symbol === "USDT") {
      return `${depositInstruction?.asset?.symbol} on ${depositInstruction.asset?.network?.name}`;
    } else {
      return depositInstruction.asset.symbol;
    }
  };

  const showDepositInstructions = async (instructions: any) => {
    setSelectedCryptoDepositInstructions(instructions);
  };

  return (
    <>
      <main className="bg-grey p-6">
        <div className="flex flex-row items-center mb-2">
          <h4 className="text-xl font-bold dark:text-black">Crypto & Fiat</h4>
        </div>
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <p className="mt-2 text-sm text-gray-700">
              To request additional asset addresses, please contact{" "}
              <a href="mailto:support@ansiblelabs.xyz" className="underline">
                support@ansiblelabs.xyz
              </a>
            </p>
          </div>
        </div>
        <div className="mt-8 flow-root col=span-2">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="p-4 text-left text-sm font-semibold text-gray-900 w-[100px]"
                      ></th>
                      <th
                        scope="col"
                        className="p-4 text-left text-sm font-semibold text-gray-900 w-[200px]"
                      >
                        Asset
                      </th>
                      <th
                        scope="col"
                        className="p-4 text-sm font-semibold text-gray-900 w-[200px] text-end"
                      >
                        <Tooltip text="Funds that are available for immediate use">
                          <InformationCircleIcon
                            height={20}
                            width={20}
                            className="mr-2"
                          />
                        </Tooltip>
                        Available balance
                      </th>
                      <th
                        scope="col"
                        className="p-4 text-sm font-semibold text-gray-900 w-[200px] text-end"
                      >
                        <Tooltip text="Funds that were locked when the user placed a buy/sell order. Once the order is either executed or canceled, the amount will decrease.">
                          <InformationCircleIcon
                            height={20}
                            width={20}
                            className="mr-2"
                          />
                        </Tooltip>
                        Locked balance
                      </th>
                      <th
                        scope="col"
                        className="p-4 text-sm font-semibold text-gray-900 w-[200px] text-end"
                      >
                        <Tooltip text="Pending incoming funds. Once the order is settled, the amount will become available.">
                          <InformationCircleIcon
                            height={20}
                            width={20}
                            className="mr-2"
                          />
                        </Tooltip>
                        Pending balance
                      </th>
                      <th
                        scope="col"
                        className="p-4 text-sm font-semibold text-gray-900 w-[200px] text-end"
                      >
                        <Tooltip text="Sum of the Available and Locked balances.">
                          <InformationCircleIcon
                            height={20}
                            width={20}
                            className="mr-2"
                          />
                        </Tooltip>
                        Total balance
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {fiatDepositInstructions.map((instruction: any) => (
                      <tr
                        key={instruction.id}
                        onClick={() => {
                          setSelectedFiatDepositInstructions(instruction);
                        }}
                        className="hover:bg-gray-100 cursor-pointer"
                      >
                        <td className="whitespace-nowrap p-4 text-sm text-gray-500">
                          {getIcon("USD")}
                        </td>
                        <td className="whitespace-nowrap p-4 text-sm text-gray-500">
                          {getName(instruction)}
                        </td>
                        <td className="whitespace-nowrap p-4 text-sm text-gray-500 text-end">
                          {instruction.disbursable} {instruction.asset.symbol}
                        </td>
                        <td className="whitespace-nowrap p-4 text-sm text-gray-500 text-end">
                          {instruction.locked} {instruction.asset.symbol}
                        </td>
                        <td className="whitespace-nowrap p-4 text-sm text-gray-500 text-end">
                          {instruction.pending} {instruction.asset.symbol}
                        </td>
                        <td className="whitespace-nowrap p-4 text-sm text-gray-500 text-end">
                          {instruction.total} {instruction.asset.symbol}
                        </td>
                      </tr>
                    ))}
                    {cryptoDepositInstructions.map((instruction: any) => (
                      <tr
                        key={instruction.id}
                        onClick={() => {
                          showDepositInstructions(instruction);
                        }}
                        className="hover:bg-gray-100 cursor-pointer"
                      >
                        <td className="whitespace-nowrap p-4 text-sm text-gray-500">
                          {getIcon(
                            instruction.asset.symbol,
                            instruction.asset.network.fortressNetworkId,
                          )}
                        </td>
                        <td className="whitespace-nowrap p-4 text-sm text-gray-500">
                          {getName(instruction)}
                        </td>
                        <td className="whitespace-nowrap p-4 text-sm text-gray-500 text-end">
                          {instruction.disbursable} {instruction.asset.symbol}
                        </td>
                        <td className="whitespace-nowrap p-4 text-sm text-gray-500 text-end">
                          {instruction.locked} {instruction.asset.symbol}
                        </td>
                        <td className="whitespace-nowrap p-4 text-sm text-gray-500 text-end">
                          {instruction.pending} {instruction.asset.symbol}
                        </td>
                        <td className="whitespace-nowrap p-4 text-sm text-gray-500 text-end">
                          {instruction.total} {instruction.asset.symbol}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </main>
      {loading && <Loader />}
      {selectedCryptoDepositInstructions && (
        <CryptoDepositInstructions
          instructions={selectedCryptoDepositInstructions}
          open={!!selectedCryptoDepositInstructions}
          setOpen={() => setSelectedCryptoDepositInstructions(null)}
        />
      )}
      {selectedFiatDepositInstructions && (
        <FiatDepositInstructions
          instructions={selectedFiatDepositInstructions}
          open={!!selectedFiatDepositInstructions}
          setOpen={() => {
            auth.updateUser({
              ...auth.currentUser,
              selectedAccount: {
                ...auth.currentUser?.selectedAccount,
              },
            });
            getDepositInstructions();
            setSelectedFiatDepositInstructions(null);
          }}
        />
      )}
    </>
  );
}
