import { useState, useRef } from "react";
import Dialog from "../../../../components/Dialog";
import { Dialog as ReactDialog } from "@headlessui/react";

const AddWalletDialog = ({
  open,
  setOpen,
  onSubmit,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onSubmit: (body: any) => void;
}) => {
  const [addressDetails, setAddressDetails] = useState<any>({
    description: "",
    address: "",
  });
  const cancelButtonRef = useRef(null);
  const handleWallet = () => {
    setOpen(false);
    console.log(addressDetails);
    onSubmit(addressDetails);
  };
  return (
    <Dialog open={open} setOpen={setOpen}>
      <div>
        <div className="mt-3 text-center sm:mt-5">
          <ReactDialog.Title
            as="h3"
            className="text-base font-semibold leading-6 text-gray-900"
          >
            Add Wallet
          </ReactDialog.Title>
          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
            <label
              htmlFor="email"
              className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 text-left"
            >
              Description
            </label>
            <div className="mt-2 sm:col-span-2 sm:mt-0">
              <input
                id="description"
                name="description"
                type="text"
                autoComplete="off"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-md sm:text-sm sm:leading-6"
                value={addressDetails?.description}
                onChange={(e) => {
                  setAddressDetails({
                    ...addressDetails,
                    description: e.target.value,
                  });
                }}
              />
            </div>
          </div>
          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
            <label
              htmlFor="email"
              className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 text-left"
            >
              Address
            </label>
            <div className="mt-2 sm:col-span-2 sm:mt-0">
              <input
                id="address"
                name="address"
                type="text"
                autoComplete="off"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-md sm:text-sm sm:leading-6"
                value={addressDetails?.address}
                onChange={(e) => {
                  setAddressDetails({
                    ...addressDetails,
                    address: e.target.value,
                  });
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
        <button
          type="submit"
          className="inline-flex w-full justify-center rounded-md bg-lavender-400 hover:bg-lavender-600 px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 sm:col-start-2  disabled:bg-lavender-200 disabled:text-lavender-700 disabled:opacity-50"
          onClick={handleWallet}
          disabled={!addressDetails.description || !addressDetails.address}
        >
          Add Wallet
        </button>
        <button
          type="button"
          className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
          onClick={() => setOpen(false)}
          ref={cancelButtonRef}
        >
          Cancel
        </button>
      </div>
    </Dialog>
  );
};

export default AddWalletDialog;
