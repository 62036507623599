import { useState, useEffect } from "react";
import axios from "axios";

import { BACKEND_URL } from "../../config";
import {
  usBankAccountNumberInputRegex,
  usBankRoutingNumberInputRegex,
  usBankAccountNumber,
  abaRoutingNumber,
} from "../../utils/regex";

type Props = {
  loading: boolean;
  submitWirePaymentInfo: (data: WirePaymentInfo) => void;
  onClose: () => void;
};

type PhysicalAddress = {
  street1: string;
  street2?: string;
  postalCode: string;
  city: string;
  state: string;
  country: string;
};

export type WirePaymentInfo = {
  accountNumber: string;
  routingNumber: string;
  receiverFirstName: string;
  receiverLastName: string;
  receiverAddress: PhysicalAddress;
  receiverBankName?: string;
  receiverBankAddress: PhysicalAddress;
  description?: string;
};

export default function WirePaymentInfoForm({
  submitWirePaymentInfo,
  loading = false,
  onClose,
}: Props) {
  const [usStateMappings, setUsStateMappings] = useState<{
    [state: string]: string;
  }>({});
  const [bankDetails, setBankDetails] = useState<WirePaymentInfo>({
    accountNumber: "",
    routingNumber: "",
    receiverFirstName: "",
    receiverLastName: "",
    receiverAddress: { country: "US" } as PhysicalAddress,
    receiverBankName: "",
    receiverBankAddress: { country: "US" } as PhysicalAddress,
    description: "",
  });

  useEffect(() => {
    const getUsStateMappings = async () => {
      const { data } = await axios.get(
        `${BACKEND_URL}/onboarding/usStateMappings`,
      );
      setUsStateMappings(data);
    };
    getUsStateMappings();
  }, []);

  const handleRoutingNumberChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = (e.target as HTMLInputElement).value;

    if (RegExp(usBankRoutingNumberInputRegex).test(value)) {
      setBankDetails({
        ...bankDetails,
        routingNumber: value,
      });
    }
  };

  const handleAccountNumberChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = (e.target as HTMLInputElement).value;

    if (RegExp(usBankAccountNumberInputRegex).test(value)) {
      setBankDetails({
        ...bankDetails,
        accountNumber: value,
      });
    }
  };

  return (
    <div className="flex flex-col">
      <div className="flex flex-col">
        <div className="flex flex-col mt-2">
          <label
            htmlFor="bank-address-city"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Account Description
          </label>
          <input
            required
            value={bankDetails?.description ?? ""}
            type="text"
            name="account-description"
            id="account-description"
            className="block w-half rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-lavender-700 sm:text-sm sm:leading-6"
            placeholder="Main Business Account - 123 First Bank"
            onChange={(e) => {
              setBankDetails({
                ...bankDetails,
                description: e.target.value,
              });
            }}
          />
        </div>
        <p className="block text-sm font-medium leading-6 text-gray-900 mt-4">
          Recipient Information
        </p>
        <div className="flex flex-col mt-6 ml-2">
          <label
            htmlFor="receiver-first-name"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Recipient First Name
          </label>
          <input
            required
            value={bankDetails?.receiverFirstName ?? ""}
            type="text"
            name="receiver-first-name"
            id="receiver-first-name"
            className="block w-half rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-lavender-700 sm:text-sm sm:leading-6"
            placeholder=""
            onChange={(e) => {
              setBankDetails({
                ...bankDetails,
                receiverFirstName: e.target.value,
              });
            }}
          />
        </div>
        <div className="flex flex-col mt-2 ml-2">
          <label
            htmlFor="receiver-last-name"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Recipient Last Name
          </label>
          <input
            required
            value={bankDetails?.receiverLastName ?? ""}
            type="text"
            name="receiver-last-name"
            id="receiver-last-name"
            className="block w-half rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-lavender-700 sm:text-sm sm:leading-6"
            placeholder=""
            onChange={(e) => {
              setBankDetails({
                ...bankDetails,
                receiverLastName: e.target.value,
              });
            }}
          />
        </div>
      </div>
      <div id="receiver-address-section" className="flex flex-col mt-4">
        <label
          htmlFor="receiver-address"
          className="block text-sm font-medium leading-6 text-gray-900 mt-2"
        >
          Recipient Address
        </label>
        <div className="flex flex-col mt-6 ml-2">
          <div className="flex flex-col mr-0">
            <label
              htmlFor="receiver-address-street1"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Street 1
            </label>
            <input
              required
              value={bankDetails?.receiverAddress?.street1 ?? ""}
              type="text"
              name="receiver-street1"
              id="receiver-street1"
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-lavender-700 sm:text-sm sm:leading-6"
              placeholder=""
              onChange={(e) => {
                setBankDetails({
                  ...bankDetails,
                  receiverAddress: {
                    ...bankDetails.receiverAddress,
                    street1: e.target.value,
                  },
                });
              }}
            />
          </div>
          <div className="flex flex-col mt-2">
            <label
              htmlFor="receiver-address-street2"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Street 2 (optional)
            </label>
            <input
              type="text"
              value={bankDetails?.receiverAddress?.street2 ?? ""}
              name="receiver-street2"
              id="receiver-street2"
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-lavender-700 sm:text-sm sm:leading-6"
              placeholder=""
              onChange={(e) => {
                setBankDetails({
                  ...bankDetails,
                  receiverAddress: {
                    ...bankDetails.receiverAddress,
                    street2: e.target.value,
                  },
                });
              }}
            />
          </div>
        </div>
        <div className="flex flex-col mt-2 ml-2">
          <label
            htmlFor="receiver-address-city"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            City
          </label>
          <input
            required
            value={bankDetails?.receiverAddress?.city ?? ""}
            type="text"
            name="receiver-address-city"
            id="receiver-address-city"
            className="block w-half rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-lavender-700 sm:text-sm sm:leading-6"
            placeholder=""
            onChange={(e) => {
              setBankDetails({
                ...bankDetails,
                receiverAddress: {
                  ...bankDetails.receiverAddress,
                  city: e.target.value,
                },
              });
            }}
          />
        </div>
        <div className="flex flex-col md:flex-row mt-2 ml-2">
          <div className="flex flex-col md:w-1/2 md:mr-2">
            <label
              htmlFor="receiver-address-state"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              State
            </label>
            <select
              required
              value={bankDetails?.receiverAddress?.state ?? ""}
              name="receiver-address-state"
              id="receiver-address-state"
              className="block w-half rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-lavender-700 sm:text-sm sm:leading-6"
              onChange={(e) => {
                setBankDetails({
                  ...bankDetails,
                  receiverAddress: {
                    ...bankDetails.receiverAddress,
                    state: e.target.value,
                  },
                });
              }}
            >
              <option></option>
              {Object.keys(usStateMappings).map((key) => (
                <option value={key}>{usStateMappings[key]}</option>
              ))}
            </select>
          </div>
          <div className="flex flex-col md:w-1/2 md:mr-2">
            <label
              htmlFor="receiver-address-postal-code"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Postal Code
            </label>
            <input
              required
              value={bankDetails?.receiverAddress?.postalCode ?? ""}
              type="text"
              name="receiver-addiress-postal-code"
              id="receiver-addiress-postal-code"
              className="block w-half rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-lavender-700 sm:text-sm sm:leading-6"
              placeholder=""
              onChange={(e) => {
                setBankDetails({
                  ...bankDetails,
                  receiverAddress: {
                    ...bankDetails.receiverAddress,
                    postalCode: e.target.value,
                  },
                });
              }}
            />
          </div>
        </div>
      </div>
      <div id="bank-info-section" className="flex flex-col mt-8">
        <p className="block text-sm font-medium leading-6 text-gray-900">
          Beneficiary Financial Information
        </p>
        <div className="flex flex-col ml-2">
          <div className="flex flex-col mt-5">
            <div className="flex flex-col">
              <label
                htmlFor="receiver-name"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Bank Name
              </label>
              <input
                value={bankDetails?.receiverBankName ?? ""}
                type="text"
                name="receiver-bank-name"
                id="receiver-bank-name"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-lavender-700 sm:text-sm sm:leading-6"
                placeholder=""
                onChange={(e) => {
                  setBankDetails({
                    ...bankDetails,
                    receiverBankName: e.target.value,
                  });
                }}
              />
            </div>
          </div>
          <div className="flex flex-col mt-2">
            <div className="flex flex-col mr-0">
              <label
                htmlFor="routing-number"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Routing Number
              </label>
              <input
                required
                value={bankDetails?.routingNumber ?? ""}
                type="text"
                name="routing-number"
                id="routing-number"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-lavender-700 sm:text-sm sm:leading-6"
                placeholder="122105278"
                onChange={handleRoutingNumberChange}
              />
            </div>
            <div className="flex flex-col mt-2">
              <label
                htmlFor="account-number"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Account Number
              </label>
              <input
                required
                value={bankDetails?.accountNumber ?? ""}
                type="text"
                name="account-number"
                id="account-number"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-lavender-700 sm:text-sm sm:leading-6"
                placeholder="122105278"
                onChange={handleAccountNumberChange}
              />
            </div>
          </div>
          <div id="bank-address-section" className="pb-2 flex flex-col mt-4">
            <label
              htmlFor="bank-address"
              className="block text-sm font-medium leading-6 text-gray-900 mb-2"
            >
              Bank Address
            </label>
            <div className="flex flex-col">
              <div className="flex flex-col mr-0">
                <label
                  htmlFor="bank-address-street1"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Street 1
                </label>
                <input
                  required
                  value={bankDetails?.receiverBankAddress?.street1 ?? ""}
                  type="text"
                  name="bank-street1"
                  id="bank-street1"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-lavender-700 sm:text-sm sm:leading-6"
                  placeholder=""
                  onChange={(e) => {
                    setBankDetails({
                      ...bankDetails,
                      receiverBankAddress: {
                        ...bankDetails.receiverBankAddress,
                        street1: e.target.value,
                      },
                    });
                  }}
                />
              </div>
              <div className="flex flex-col mt-2">
                <label
                  htmlFor="bank-address-street2"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Street 2 (optional)
                </label>
                <input
                  type="text"
                  value={bankDetails?.receiverBankAddress?.street2 ?? ""}
                  name="bank-street2"
                  id="bank-street2"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-lavender-700 sm:text-sm sm:leading-6"
                  placeholder=""
                  onChange={(e) => {
                    setBankDetails({
                      ...bankDetails,
                      receiverBankAddress: {
                        ...bankDetails.receiverBankAddress,
                        street2: e.target.value,
                      },
                    });
                  }}
                />
              </div>
              <div className="flex flex-col mt-2">
                <label
                  htmlFor="bank-address-city"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  City
                </label>
                <input
                  required
                  value={bankDetails?.receiverBankAddress?.city ?? ""}
                  type="text"
                  name="bank-address-city"
                  id="bank-address-city"
                  className="block w-half rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-lavender-700 sm:text-sm sm:leading-6"
                  placeholder=""
                  onChange={(e) => {
                    setBankDetails({
                      ...bankDetails,
                      receiverBankAddress: {
                        ...bankDetails.receiverBankAddress,
                        city: e.target.value,
                      },
                    });
                  }}
                />
              </div>
              <div className="flex flex-col md:flex-row mt-2">
                <div className="flex flex-col md:w-1/2 md:mr-2">
                  <label
                    htmlFor="bank-address-state"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    State
                  </label>
                  <select
                    required
                    value={bankDetails?.receiverBankAddress?.state ?? ""}
                    name="bank-address-state"
                    id="bank-address-state"
                    className="block w-half rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-lavender-700 sm:text-sm sm:leading-6"
                    onChange={(e) => {
                      setBankDetails({
                        ...bankDetails,
                        receiverBankAddress: {
                          ...bankDetails.receiverBankAddress,
                          state: e.target.value,
                        },
                      });
                    }}
                  >
                    <option></option>
                    {Object.keys(usStateMappings).map((key) => (
                      <option value={key}>{usStateMappings[key]}</option>
                    ))}
                  </select>
                </div>
                <div className="flex flex-col md:w-1/2 md:ml-2">
                  <label
                    htmlFor="bank-address-postal-code"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Postal Code
                  </label>
                  <input
                    required
                    value={bankDetails?.receiverBankAddress?.postalCode ?? ""}
                    type="text"
                    name="bank-addiress-postal-code"
                    id="bank-addiress-postal-code"
                    className="block w-half rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-lavender-700 sm:text-sm sm:leading-6"
                    placeholder=""
                    onChange={(e) => {
                      setBankDetails({
                        ...bankDetails,
                        receiverBankAddress: {
                          ...bankDetails.receiverBankAddress,
                          postalCode: e.target.value,
                        },
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-5 sm:mt-6 w-full self-end gap-2 flex flex-row">
          <button
            type="button"
            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
            onClick={() => onClose()}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="inline-flex w-full justify-center rounded-md bg-lavender-400 hover:bg-lavender-600 px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 sm:col-start-2 disabled:bg-lavender-200 disabled:text-lavender-700 disabled:opacity-50"
            onClick={() => submitWirePaymentInfo(bankDetails)}
            disabled={
              !RegExp(usBankAccountNumber).test(bankDetails.accountNumber) ||
              !RegExp(abaRoutingNumber).test(bankDetails.routingNumber) ||
              !bankDetails.receiverFirstName ||
              !bankDetails.receiverLastName ||
              !bankDetails.receiverAddress?.street1 ||
              !bankDetails.receiverAddress?.postalCode ||
              bankDetails.receiverAddress?.postalCode?.length !== 5 ||
              !bankDetails.receiverAddress?.city ||
              !bankDetails.receiverAddress?.state ||
              !bankDetails.receiverAddress?.country ||
              !bankDetails.receiverBankAddress?.street1 ||
              !bankDetails.receiverBankAddress?.postalCode ||
              bankDetails.receiverBankAddress?.postalCode?.length !== 5 ||
              !bankDetails.receiverBankAddress?.city ||
              !bankDetails.receiverBankAddress?.state ||
              !bankDetails.receiverBankAddress?.country ||
              !bankDetails.receiverBankName
            }
          >
            {loading ? (
              <svg
                className="mr-3 h-5 w-5 animate-spin text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            ) : null}
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}
