import React from "react";

const Embedded: React.FC = () => {
  console.log("Embedded view");
  return (
    <div className="text-center mt-32">
      <h3 className="mt-2 text-lg font-semibold text-gray-900">
        Your account is not enabled for Embedded access.
      </h3>
      <p className="mt-2 text-sm text-gray-500">
        Please reach out to{" "}
        <a href="mailto:support@ansiblelabs.xyz">support@ansiblelabs.xyz</a> to
        request access.
      </p>
    </div>
  );
};

export default Embedded;
