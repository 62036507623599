import { format } from "date-fns";
import Dialog from "./Dialog";
import { copy } from "../utils/copy";
import { useToast } from "../contexts/ToastContext";

const ApiLog = ({
  open,
  setOpen,
  apiLog,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  apiLog: any;
}) => {
  const { showToast } = useToast();
  const {
    responseCode,
    path,
    method,
    createdAt,
    ipAddress,
    request,
    response,
  } = apiLog;

  const copyText = (text: string) => {
    copy(text);
    showToast("Copied to clipboard");
  };

  return (
    <Dialog open={open} setOpen={setOpen} size="lg">
      <div className="mt-3 sm:mt-5 mb-12">
        <div className="flex justify-between items-center">
          <div className="flex-grow text-start -mt-4">
            <h6 className="text-md leading-8 text-gray-900">
              {method} {path}
            </h6>
          </div>
          <button
            className="p-2 absolute right-3 top-3" // Button styling
            onClick={() => {
              setOpen(false);
            }}
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              onClick={() => setOpen(false)}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              ></path>
            </svg>
          </button>
        </div>
        <div className="mt-6 border-t border-gray-100">
          <dl className="divide-y divide-gray-100">
            <div className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-2 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                IP Address
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                {ipAddress}
              </dd>
            </div>
            <div className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                Response Code
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                {responseCode}
              </dd>
            </div>
            <div className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                Request Date
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                {format(createdAt, "MMMM dd, yyyy, h:mm a zzz")}
              </dd>
            </div>
          </dl>
          <div className="bg-gray-900 text-white p-4 rounded-md mt-8">
            <div className="flex justify-between items-center mb-2">
              <span className="text-gray-400 text-sm">
                API request headers:
              </span>
              <button
                className="code bg-gray-800 hover:bg-gray-700 text-gray-300 px-3 py-1 rounded-md text-sm"
                data-clipboard-target="#code"
                onClick={() =>
                  copyText(JSON.stringify(request.headers, null, 2))
                }
              >
                Copy
              </button>
            </div>
            <div className="overflow-x-auto">
              <pre id="code" className="text-gray-300 text-sm">
                <code>{JSON.stringify(request.headers, null, 2)}</code>
              </pre>
            </div>
          </div>
          <div className="bg-gray-900 text-white p-4 rounded-md mt-8">
            <div className="flex justify-between items-center mb-2">
              <span className="text-gray-400 text-sm">API request:</span>
              <button
                className="code bg-gray-800 hover:bg-gray-700 text-gray-300 px-3 py-1 rounded-md text-sm"
                data-clipboard-target="#code"
                onClick={() => copyText(JSON.stringify(request.body, null, 2))}
              >
                Copy
              </button>
            </div>
            <div className="overflow-x-auto">
              <pre id="code" className="text-gray-300 text-sm">
                <code>{JSON.stringify(request.body, null, 2)}</code>
              </pre>
            </div>
          </div>
          <div className="bg-gray-900 text-white p-4 rounded-md mt-8">
            <div className="flex justify-between items-center mb-2">
              <span className="text-gray-400 text-sm">API response:</span>
              <button
                className="code bg-gray-800 hover:bg-gray-700 text-gray-300 px-3 py-1 rounded-md text-sm"
                data-clipboard-target="#code"
                onClick={() => copyText(JSON.stringify(response, null, 2))}
              >
                Copy
              </button>
            </div>
            <div className="overflow-x-auto">
              <pre id="code" className="text-gray-300 text-sm">
                <code>{JSON.stringify(response, null, 2)}</code>
              </pre>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default ApiLog;
