import React from "react";

type Props = {
  position?:
    | "fixed"
    | "absolute"
    | "relative"
    | "static"
    | "sticky"
    | "initial"
    | "inherit";
  outerWidth?: string;
  outerHeight?: string;
  innerWidth?: string;
  innerHeight?: string;
  backgroundColor?: string;
  thickness?: string;
  innerMargin?: string;
  overlayWidth?: string;
  overlayPadding?: string;
};

const Loader = ({
  position = "fixed",
  outerWidth = "80px",
  outerHeight = "80px",
  innerWidth = "64px",
  innerHeight = "64px",
  thickness = "6px",
  innerMargin = "8px",
  overlayWidth = "auto",
  overlayPadding = "0px",
  backgroundColor = "rgba(0, 0, 0, 0.5)", // Semi-transparent background
}: Props) => {
  const overlayStyle = {
    position, // Overlay positioned over entire screen
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor,
    zIndex: 1000, // High z-index to be above other content
    display: "flex",
    alignItems: "center", // Center the loader vertically
    justifyContent: "center", // Center the loader horizontally
    width: overlayWidth,
    padding: overlayPadding,
  } as any;

  const loaderStyle = {
    display: "inline-block",
    width: outerWidth,
    height: outerHeight,
    zIndex: 1001, // Ensuring loader is above the overlay
  };

  const afterStyle = {
    content: '""',
    display: "block",
    width: innerWidth,
    height: innerHeight,
    margin: innerMargin,
    borderRadius: "50%",
    border: `${thickness} solid #fff`,
    borderColor: "#af96ec transparent #af96ec transparent",
    animation: "lds-dual-ring 1.2s linear infinite",
  };

  return (
    <div style={overlayStyle}>
      <style>
        {`
          @keyframes lds-dual-ring {
            0% {
              transform: rotate(0deg);
            }
            100% {
              transform: rotate(360deg);
            }
          }

          // Ensuring that pointer events are only allowed on the loader
          body * {
            pointer-events: none;
          }
          .lds-dual-ring {
            pointer-events: auto;
          }
        `}
      </style>
      <div style={loaderStyle} className="lds-dual-ring">
        <div style={afterStyle} />
      </div>
    </div>
  );
};

export default Loader;
