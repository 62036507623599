import React from "react";
import Loader from "../../components/Loader";

interface Props {
  onAccept: () => void;
}

const MyComponent: React.FC<Props> = ({ onAccept }) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const acceptTerms = async () => {
    setLoading(true);
    onAccept();
  };
  return (
    <>
      <div className="overflow-auto">
        <p className="text-lg leading-8 text-gray-600">
          By clicking Accept you agree to the following
        </p>
        <div className="mt-4">
          <a
            href="https://uploads-ssl.webflow.com/63752ade0dbac24c119e17f7/64dba51dfb45dfb2ea18cbb3_Ansible%20Labs%20Privacy%20Policy.pdf"
            target="_blank"
            className="text-blue-500 hover:text-blue-700 underline"
            rel="noreferrer"
          >
            Ansible Labs Privacy Policy
          </a>
        </div>
        <div className="mt-4">
          <a
            href="https://assets-global.website-files.com/654021b25420a3364e320a74/654021c2b4b8058a9a2b1283_Ansible Labs_Business Terms of Use.pdf"
            target="_blank"
            className="text-blue-500 hover:text-blue-700 underline"
            rel="noreferrer"
          >
            Ansible Labs Terms of Use
          </a>
        </div>
        <div className="mt-4">
          <a
            href="https://vault.sandbox.fortressapi.com/nft-origin/8321fd2d-bfbc-430d-ad74-17f382c03dc7.pdf"
            target="_blank"
            className="text-blue-500 hover:text-blue-700 underline"
            rel="noreferrer"
          >
            Fortress Trust Custodial Account Agreement
          </a>
        </div>
        <div className="mt-4">
          <a
            href="https://fortresstrustcompany.com/disclosures-e-sign"
            target="_blank"
            className="text-blue-500 hover:text-blue-700 underline"
            rel="noreferrer"
          >
            E-Sign Agreement
          </a>
        </div>
        <div className="mt-4">
          <a
            href="https://fortresstrustcompany.com/disclosures-consumer"
            target="_blank"
            className="text-blue-500 hover:text-blue-700 underline"
            rel="noreferrer"
          >
            Consumer Disclosures
          </a>
        </div>
        <div className="mt-6">
          <p className="text-md dark:text-black">USA PATRIOT ACT DISCLOSURE</p>
          <p className="text-sm text-gray-600 mt-6">
            IMPORTANT INFORMATION ABOUT PROCEDURES FOR OPENING A NEW ACCOUNT: To
            help the government fight money laundering and the funding of
            terrorism, federal law requires all financial institutions to
            obtain, verify, and record information that identifies each person
            or entity they conduct business with. What this means for you: we
            will ask for information pertaining to your business that will allow
            us to verify the entity, such as the entity name and structure,
            jurisdiction of formation, entity’s physical address, and other
            information that will allow us to identify the business. We will
            also ask for information pertaining to ultimate beneficial owners
            (“UBOs”), who are individuals or entities that own or control at
            least 25% of your business, as well as any Officers, Directors, and
            Authorized Users of the business.
          </p>
        </div>
        <button
          onClick={acceptTerms}
          type="button"
          className="rounded-md bg-lavender-400 hover:bg-lavender-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 mt-8"
          disabled={loading}
        >
          Accept
        </button>
      </div>
      {loading && <Loader />}
    </>
  );
};

export default MyComponent;
