import React from "react";
import { usePrivy } from "@privy-io/react-auth";

import BeamLogo from "../assets/beam-logo-black-period.png";

export default function Login() {
  const { login } = usePrivy();

  return (
    <>
      <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
        <div className="mb-8">
          <div className="flex items-center justify-center mb-4">
            <img src={BeamLogo} alt="logo" width={250} />
          </div>
          <p className="text-center text-lg text-gray-600">
            We make it easy to cash out your crypto
          </p>
        </div>

        <button
          type="button"
          onClick={login}
          className="rounded-md bg-lavender-400 mt-8 w-64 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-lavender-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-bg-lavender-600"
        >
          Log In or Sign Up
        </button>
      </div>
    </>
  );
}
