import React, { useState } from "react";

export const Tooltip = ({
  children,
  text,
}: {
  children: React.ReactNode;
  text: string;
}) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const toggleTooltip = () => {
    setShowTooltip(!showTooltip);
  };

  return (
    <div
      className="relative inline-block cursor-pointer align-bottom"
      onMouseEnter={toggleTooltip}
      onMouseLeave={toggleTooltip}
    >
      {children}
      <div
        className={`px-4 py-2 bg-black text-white rounded-md z-index-200 absolute right-[-100px] top-[30px] w-[200px] ${
          showTooltip ? "visible" : "invisible"
        }`}
      >
        {text}
      </div>
    </div>
  );
};
