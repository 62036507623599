import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
} from "react";
import { Transition } from "@headlessui/react";

interface ToastContextType {
  showToast: (title: string, description?: string, icon?: JSX.Element) => void;
  hideToast: () => void;
  toast: {
    show: boolean;
    title: string;
    description: string;
    icon?: JSX.Element;
  };
}

// Toast context with default values
const ToastContext = createContext<ToastContextType>({
  showToast: (title: string, description?: string, icon?: JSX.Element) => {},
  hideToast: () => {},
  toast: {
    show: false,
    title: "",
    description: "",
    icon: <></>,
  },
});

// Provider component
export const ToastProvider = ({ children }: any) => {
  const [toast, setToast] = useState({
    show: false,
    title: "",
    description: "",
    icon: <></>,
  });

  const showToast = useCallback(
    (title: string, description: string = "", icon: any) => {
      console.log(icon, "d");
      setToast({ show: true, title, description, icon });
    },
    [],
  );

  const hideToast = useCallback(() => {
    setToast({ show: false, title: "", description: "", icon: <></> });
  }, []);

  return (
    <ToastContext.Provider value={{ showToast, hideToast, toast }}>
      {children}
      <Toast /> {/* The Toast component is used here */}
    </ToastContext.Provider>
  );
};

// Custom hook to use the toast
export const useToast = () => useContext(ToastContext);

// Toast component
const Toast = () => {
  const { toast, hideToast } = useContext(ToastContext) as ToastContextType;

  // Disappear after 2 seconds
  useEffect(() => {
    if (toast.show) {
      const timer = setTimeout(hideToast, 2000);
      return () => clearTimeout(timer);
    }
  }, [toast.show, hideToast]);

  return (
    <Transition
      show={toast.show}
      as={React.Fragment}
      enter="transform ease-out duration-300 transition"
      enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
      enterTo="translate-y-0 opacity-100 sm:translate-x-0"
      leave="transition ease-in duration-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div
        aria-live="assertive"
        className="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6 z-50"
      >
        <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
          <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
            <div className="p-4">
              <div className="flex items-center">
                {toast.icon}
                <div className="ml-3 w-0 flex-1 pt-0.5">
                  {toast.title ? (
                    <p className="text-sm font-medium text-gray-900">
                      {toast.title}
                    </p>
                  ) : null}
                  {toast.description ? (
                    <p className="mt-1 text-sm text-gray-500">
                      {toast.description}
                    </p>
                  ) : null}
                </div>
                <div className="ml-4 flex flex-shrink-0">
                  <button
                    type="button"
                    className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    onClick={hideToast}
                  >
                    <span className="sr-only">Close</span>
                    {/* <XMarkIcon className="h-5 w-5" aria-hidden="true" /> */}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  );
};
