import { useState } from "react";
import Dialog from "../../../../components/Dialog";
import { Dialog as ReactDialog } from "@headlessui/react";
import Select from "../../../../components/SelectMenu";
import ACHPaymentInfoForm, { ACHPaymentInfo } from "../../ACHPaymentInfoForm";
import WirePaymentInfoForm, {
  WirePaymentInfo,
} from "../../WirePaymentInfoForm";
import API from "../../../../api/api";
import { useAuth } from "../../../../contexts/Auth";

const AddBankDialog = ({
  open,
  setOpen,
  onSubmit,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onSubmit: (data: any | null) => void;
}) => {
  const auth = useAuth();
  const accountId = auth.currentUser?.selectedAccount?.accountId;

  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState({ id: 1, name: "ACH" }); // ["ACH", "Wire"]

  const submitACHPaymentInfo = async (bankDetails: ACHPaymentInfo) => {
    setLoading(true);
    try {
      const { data } = await API.post(
        `business/users/${auth.currentUser?.id}/bank`,
        {
          ...bankDetails,
          accountName: bankDetails.description,
          financialAccountsProvider: "plainAchInstructions",
          accountId: accountId,
        },
      );
      await onSubmit(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("error", error);
    }
  };

  const submitWirePaymentInfo = async (bankDetails: WirePaymentInfo) => {
    setLoading(true);
    // remove receiverFirstName and receiverLastName from bankDetails and add them as receiverName using spread
    const { receiverFirstName, receiverLastName, ...rest } = bankDetails;
    const body = {
      ...rest,
      receiverName: `${receiverFirstName} ${receiverLastName}`,
    };
    const { data } = await API.post(
      `business/users/${auth.currentUser?.id}/wire`,
      { ...body, accountId: accountId },
    );
    await onSubmit(data);
    setLoading(false);
  };

  return (
    <Dialog open={open} setOpen={setOpen}>
      <div className="mt-3 sm:mt-5">
        <ReactDialog.Title
          as="h3"
          className="text-base font-semibold leading-6 text-gray-900"
        >
          Add Bank Account
        </ReactDialog.Title>
        <div className="sm:grid sm:items-start sm:py-6">
          <Select
            selected={selected}
            setSelected={setSelected}
            title="Transfer Method"
            options={[
              { id: 1, name: "ACH" },
              { id: 2, name: "Wire" },
            ]}
          />
        </div>
        {selected?.name === "ACH" ? (
          <ACHPaymentInfoForm
            loading={loading}
            submitACHPaymentInfo={submitACHPaymentInfo}
            onClose={() => setOpen(false)}
          />
        ) : (
          <div className="full">
            <WirePaymentInfoForm
              loading={loading}
              submitWirePaymentInfo={submitWirePaymentInfo}
              onClose={() => setOpen(false)}
            />
          </div>
        )}
      </div>
    </Dialog>
  );
};

export default AddBankDialog;
