import Dialog from "./Dialog";
import QRCode from "./QRCode";
import { Square2StackIcon } from "@heroicons/react/24/outline";

import { useToast } from "../contexts/ToastContext";
import { copy } from "../utils/copy";

const CryptoDepositInstructions = ({
  open,
  setOpen,
  instructions,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  instructions: any;
}) => {
  console.log(instructions);
  const { showToast } = useToast();

  const copyToClipboard = async (text: string) => {
    const success = await copy(text);
    if (success) {
      showToast(
        "Address copied to clipboard",
        "",
        <Square2StackIcon height={20} />,
      );
    }
  };

  return (
    <Dialog open={open} setOpen={setOpen}>
      <div className="mt-3 sm:mt-5 mb-12">
        <div className="flex justify-between items-center">
          <div className="flex-grow text-center">
            <h4 className="text-2xl leading-8 text-gray-900">
              {instructions?.asset?.symbol} on{" "}
              {instructions?.asset?.network?.name}
            </h4>
          </div>
          <button
            className="p-2 absolute right-3 top-3" // Button styling
            onClick={() => {
              setOpen(false);
            }}
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              onClick={() => setOpen(false)}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              ></path>
            </svg>
          </button>
        </div>
        <div className="flex flex-col items-center justify-center">
          <div className="my-8">
            <QRCode text={instructions?.address} />
          </div>
          <p className="text-sm break-all">{instructions?.address}</p>

          <button
            className="w-full py-2 border-2 border-gray-400 rounded-md flex justify-center items-center space-x-2 hover:border-gray-600 mt-8"
            onClick={() => copyToClipboard(instructions?.address)}
          >
            <Square2StackIcon height={20} />
            <span>Copy</span>
          </button>
        </div>
      </div>
    </Dialog>
  );
};

export default CryptoDepositInstructions;
