import { useCallback, useEffect, useState } from "react";
import { format } from "date-fns";

import API from "../../api/api";
import { useAuth } from "../../contexts/Auth";
import ApiLog from "../../components/ApiLog";
import Loader from "../../components/Loader";

export default function Logs() {
  const auth = useAuth();
  const [loading, setLoading] = useState(false);
  const [selectedAccount] = useState(
    auth.currentUser?.selectedAccount?.accountId,
  );
  const [logs, setLogs] = useState([]);
  const [selectedApiLog, setSelectedApiLog] = useState(null);

  const getApiLogs = useCallback(async () => {
    setLoading(true);
    const { data } = await API.get(`business/${selectedAccount}/apiLogs`);
    if (data.length > 0) {
      setLogs(data);
    }

    setLoading(false);
  }, [selectedAccount, setLoading, setLogs]);

  useEffect(() => {
    if (selectedAccount) {
      getApiLogs();
    }
  }, [selectedAccount, getApiLogs]);
  return (
    <>
      {selectedApiLog && (
        <ApiLog
          open={!!selectedApiLog}
          setOpen={setSelectedApiLog}
          apiLog={selectedApiLog}
        />
      )}
      {loading && <Loader />}
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="p-4 text-left text-sm font-semibold text-gray-900"
                    >
                      Request
                    </th>
                    <th
                      scope="col"
                      className="p-4 text-left text-sm font-semibold text-gray-900"
                    >
                      Status
                    </th>
                    <th
                      scope="col"
                      className="p-4 text-left text-sm font-semibold text-gray-900"
                    >
                      Log Id
                    </th>
                    <th
                      scope="col"
                      className="p-4 text-left text-sm font-semibold text-gray-900"
                    >
                      Created At
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {logs.map((log) => (
                    <tr
                      key={log.id}
                      className="hover:bg-gray-100 cursor-pointer"
                      onClick={() => setSelectedApiLog(log)}
                    >
                      <td className="whitespace-nowrap p-4 text-sm font-medium text-gray-900">
                        {log.path}
                      </td>
                      <td className="whitespace-nowrap p-4 text-sm text-gray-500">
                        {log.responseCode}
                      </td>
                      <td className="whitespace-nowrap p-4 text-sm text-gray-500">
                        {log.id}
                      </td>
                      <td className="whitespace-nowrap p-4 text-sm text-gray-500">
                        {format(
                          new Date(log.createdAt),
                          "MMM dd, yyyy, h:mm a",
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
