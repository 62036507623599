import axios from "axios";
import { getAccessToken } from "@privy-io/react-auth";

import { BACKEND_URL } from "../config";

const API = axios.create({
  baseURL: BACKEND_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

API.interceptors.request.use(
  async (config) => {
    let token = await getAccessToken();
    if (!token) {
      // get the token from local storage
      token = localStorage.getItem("accessToken");
    }
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export default API;
