type PersonaEnvironment = "sandbox" | "production" | undefined;

console.log(process.env);

export const BACKEND_URL: string = process.env.REACT_APP_BACKEND_URL as string;
export const PRIVY_APP_ID: string = process.env
  .REACT_APP_PRIVY_APP_ID as string;
export const PERSONA_ENVIRONMENT: PersonaEnvironment = process.env
  .REACT_APP_PERSONA_ENVIRONMENT as PersonaEnvironment;
export const RAPID_API_KEY: string = process.env
  .REACT_APP_RAPID_API_KEY as string;
