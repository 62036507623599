const OnboardingHeader = ({
  companyName,
  location,
}: {
  companyName?: string | null;
  location?: string;
}) => {
  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol className="flex space-x-4 rounded-md bg-white px-6 shadow">
        <li className="flex">
          <div className="flex items-center">
            <svg
              className="h-full w-6 flex-shrink-0 text-gray-200"
              viewBox="0 0 24 44"
              preserveAspectRatio="none"
              fill="currentColor"
              aria-hidden="true"
            >
              <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
            </svg>
            <a
              href="/accounts"
              className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
            >
              Onboarding
            </a>
          </div>
        </li>
        <li className="flex">
          <div className="flex items-center">
            <svg
              className="h-full w-6 flex-shrink-0 text-gray-200"
              viewBox="0 0 24 44"
              preserveAspectRatio="none"
              fill="currentColor"
              aria-hidden="true"
            >
              <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
            </svg>
            <a
              href={`/accounts/${location}`}
              className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
            >
              {companyName || location}
            </a>
          </div>
        </li>
      </ol>
    </nav>
  );
};

export default OnboardingHeader;
