import React from "react";
import { QRCode as ReactQRCode } from "react-qrcode-logo";

const QRCode = ({ text }: { text: string }) => {
  return (
    <ReactQRCode
      value={text} // The string you want to encode
      // You can add more properties according to your needs
    />
  );
};

export default QRCode;
