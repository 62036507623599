/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useState } from "react";
import { format } from "date-fns";

import API from "../../api/api";
import { useAuth } from "../../contexts/Auth";
import Loader from "../../components/Loader";
import ConfirmDialog from "../../components/Confirmation";
import { useToast } from "../../contexts/ToastContext";
import NewAPIIpAddress from "../../components/NewAPIIPAdress";

export default function IpAddresses() {
  const auth = useAuth();
  const { showToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [selectedAccount] = useState(
    auth.currentUser?.selectedAccount?.accountId,
  );
  const [ips, setIps] = useState<any>([]);
  const [addNewIpAddress, setAddNewIpAddress] = useState(false);
  const [selectedIpAddress, setSelectedIpAddress] = useState<any>(null);

  const getAPIAddresses = useCallback(async () => {
    setLoading(true);
    const { data } = await API.get(`business/${selectedAccount}/ipAddresses`);
    setIps(data);

    setLoading(false);
  }, [selectedAccount, setLoading, setIps]);

  const handleDeleteIpAddress = async (ipId: string) => {
    setLoading(true);
    await API.delete(`business/${selectedAccount}/ipAddresses/${ipId}`);
    setLoading(false);
    setIps(ips.filter((ip) => ip.id !== ipId));
    showToast("IP Address deleted successfully");
  };

  useEffect(() => {
    if (selectedAccount) {
      getAPIAddresses();
    }
  }, [selectedAccount, getAPIAddresses]);
  return (
    <>
      {loading && <Loader />}
      {addNewIpAddress && (
        <NewAPIIpAddress
          open={!!addNewIpAddress}
          setOpen={setAddNewIpAddress}
          onSuccess={(data) => {
            setIps([...ips, data]);
          }}
        />
      )}
      {selectedIpAddress && (
        <ConfirmDialog
          isOpen={!!selectedIpAddress}
          onClose={() => setSelectedIpAddress(null)}
          onConfirm={(ip) => {
            handleDeleteIpAddress(ip);
          }}
          onCancel={() => setSelectedIpAddress(null)}
          title={`Delete ${selectedIpAddress.ipAddress}?`}
          description={`Are you sure you want to delete this IP Address? This action cannot be undone.`}
          confirmText="Delete"
          cancelText="Cancel"
          valueToPassBack={selectedIpAddress?.id}
        />
      )}

      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="p-4 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Description
                    </th>
                    <th
                      scope="col"
                      className="p-4 text-left text-sm font-semibold text-gray-900"
                    >
                      IP Address
                    </th>
                    <th
                      scope="col"
                      className="p-4 text-left text-sm font-semibold text-gray-900"
                    >
                      Created by
                    </th>
                    <th
                      scope="col"
                      className="p-4 text-left text-sm font-semibold text-gray-900"
                    >
                      Created at
                    </th>
                    <th
                      scope="col"
                      className="p-4 text-right text-sm font-semibold text-gray-900"
                    >
                      <button
                        type="button"
                        className="rounded bg-lavender-400 hover:bg-lavender-600 px-2 py-1 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-lavender-600"
                        onClick={() => setAddNewIpAddress(true)}
                      >
                        Add IP Address
                      </button>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {ips.map((ip) => (
                    <tr key={ip.id}>
                      <td className="whitespace-nowrap p-4 text-sm font-medium text-gray-900 sm:pl-6">
                        {ip.description}
                      </td>
                      <td className="whitespace-nowrap p-4 text-sm text-gray-500">
                        {ip.ipAddress}
                      </td>
                      <td className="whitespace-nowrap p-4 text-sm text-gray-500">
                        {ip.createdBy}
                      </td>
                      <td className="whitespace-nowrap p-4 text-sm text-gray-500">
                        {format(ip.createdAt, "MMMM dd, yyyy, h:mm a zzz")}
                      </td>
                      <td className="relative whitespace-nowrap p-4 text-right text-sm font-medium">
                        <a
                          href="#"
                          className="text-gray-500 hover:text-lavender-900"
                          onClick={() => setSelectedIpAddress(ip)}
                        >
                          Delete<span className="sr-only">, {ip.name}</span>
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
