import { useState } from "react";
import Dialog from "../../../../components/Dialog";
import { Dialog as ReactDialog } from "@headlessui/react";

const IUnderstandDialog = ({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [disclaimer, setDisclaimer] = useState<string>("");
  const handleConfirm = () => {
    setOpen(false);
  };
  return (
    <Dialog static={true} open={open} setOpen={setOpen}>
      <div>
        <div className="mt-3 text-center sm:mt-5">
          <ReactDialog.Title
            as="h3"
            className="text-base font-semibold leading-6 text-gray-900 text-lg"
          >
            Disclaimer
          </ReactDialog.Title>
          <ReactDialog.Description className="mt-4">
            <b>Important:</b> Before you withdraw cryptocurrency from your Beam
            account, please make sure that the wallet you are sending to
            supports the specific type of cryptocurrency you are withdrawing.
            Sending cryptocurrency to an unsupported wallet address will result
            in a permanent loss of funds.
          </ReactDialog.Description>
          <ReactDialog.Description className="mt-4 text-sm">
            Beam cannot recover funds sent to an unsupported wallet. It is your
            responsibility to verify the compatibility of the wallet with the
            cryptocurrency you are withdrawing. Always double-check the wallet
            address and the type of cryptocurrency you are sending before
            confirming the transaction.
          </ReactDialog.Description>
          <div className="mt-12">
            <div className="mt-2 sm:col-span-2 sm:mt-0">
              <input
                id="disclaimer"
                name="disclaimer"
                type="text"
                placeholder="I agree"
                autoComplete="off"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-md sm:text-sm sm:leading-6"
                value={disclaimer}
                onChange={(e) => {
                  setDisclaimer(e.target.value);
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="mt-5 sm:mt-6">
        <button
          type="submit"
          disabled={disclaimer?.toLowerCase() !== "i agree"}
          className="w-full inline-flex w-full justify-center rounded-md bg-lavender-400 hover:bg-lavender-600 px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 sm:col-start-2 disabled:bg-lavender-200 disabled:text-lavender-700 disabled:opacity-50"
          onClick={handleConfirm}
        >
          Confirm
        </button>
      </div>
    </Dialog>
  );
};

export default IUnderstandDialog;
