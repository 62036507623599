import { useEffect, useState } from "react";

import Toast from "../../../../components/Toast";
import Loader from "../../../../components/Loader";
import IUnderstandDialog from "./IUnderstand";
import API from "../../../../api/api";

export default function Summary({ accountId }: { accountId: string | null }) {
  const [loading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [company, setCompany] = useState<null | {
    name: string;
    address: string;
    id: string;
    offRampWithdrawalFrequency: string;
    onRampWithdrawalFrequency: string;
    paymentId: string;
    assetId: string;
    walletId: string;
  }>(null);
  const [showDisclaimer, setShowDisclaimer] = useState<any>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = `business/onboarding/${accountId}`;
        const { data } = await API.get(url);
        setCompany({
          name: data?.name,
          address: data?.address,
          id: data?.id,
          offRampWithdrawalFrequency: data?.offRampWithdrawalFrequency,
          onRampWithdrawalFrequency: data?.onRampWithdrawalFrequency,
          paymentId: data?.paymentId,
          assetId: data?.assetId,
          walletId: data?.walletId,
        });
      } catch (error) {
        // Handle error here
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [accountId]);

  return (
    <>
      {loading && <Loader />}
      <Toast
        showIcon={false}
        show={showError}
        setShow={setShowError}
        title="An error occurred"
      />
      <div className="grid grid-cols-2 gap-2 -mt-8">
        <div className="p-6 h-auto col-span-2">
          <main className="px-4 py-16 sm:px-6 lg:flex-auto lg:px-0 lg:py-20">
            <div className="px-4 sm:px-6 lg:px-8">
              <div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">
                <div>
                  <h2 className="text-base font-semibold leading-7 text-gray-900">
                    Profile
                  </h2>
                  <p className="mt-1 text-sm leading-6 text-gray-500">
                    This business information was taken from your KYB.
                  </p>
                  <dl className="mt-6 space-y-2 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
                    <div className="pt-4 sm:flex">
                      <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                        Business Name
                      </dt>
                      <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                        <div className="text-gray-900">{company?.name}</div>
                      </dd>
                    </div>
                    <div className="pt-4 sm:flex">
                      <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                        Address
                      </dt>
                      <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                        <div className="text-gray-900">{company?.address}</div>
                      </dd>
                    </div>
                  </dl>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
      {showDisclaimer && (
        <IUnderstandDialog open={showDisclaimer} setOpen={setShowDisclaimer} />
      )}
    </>
  );
}
