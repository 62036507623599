import React from "react";

interface SubmitButtonProps {
  text: string;
  onSubmit: () => void;
  loading: boolean;
  disabled?: boolean;
  className?: string;
}

const Button: React.FC<SubmitButtonProps> = ({
  text = "",
  onSubmit,
  loading,
  disabled = false,
  className = "",
  ...props
}) => {
  // The button will be disabled if either loading or disabled prop is true
  const isDisabled = loading || disabled;

  return (
    <button
      onClick={onSubmit}
      type="button"
      className={`flex items-center rounded-md bg-lavender-400 hover:bg-lavender-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:bg-lavender-200 disabled:text-lavender-700 disabled:opacity-50 ${className}`}
      disabled={isDisabled}
      {...props}
    >
      {loading ? (
        <svg
          className="mr-3 h-5 w-5 animate-spin text-white"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          ></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
      ) : null}
      {text}
    </button>
  );
};

export default Button;
