import React from "react";

const UnderReview = () => {
  return (
    <div className="bg-yellow-100 border-l-4 border-yellow-500 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          {/* Icon representing under review status, you can replace it with an appropriate icon */}
          <svg
            className="h-5 w-5 text-yellow-500"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M10 2c4.41 0 8 3.59 8 8s-3.59 8-8 8-8-3.59-8-8 3.59-8 8-8zm0 1c-3.87 0-7 3.13-7 7s3.13 7 7 7 7-3.13 7-7-3.13-7-7-7zM9 8a1 1 0 011 1v3a1 1 0 01-2 0V9a1 1 0 011-1zm1-3.75a.75.75 0 00-1.5 0v.5a.5.5 0 11-1 0V4.25a.75.75 0 00-1.5 0V6a1 1 0 102 0V2.25a.75.75 0 00-.75-.75h-1.5a.75.75 0 000 1.5h.5a.5.5 0 110 1H9a1 1 0 100 2h2a.5.5 0 010 1H9a1 1 0 100 2h1.5a.75.75 0 000-1.5h-.5a.5.5 0 110-1h.5a.75.75 0 00.75-.75V8a1 1 0 011-1zm4.39 4.89l-.14-.14a1 1 0 00-1.3 1.5l.14.14a1 1 0 001.3-1.5z"
              clipRule="evenodd"
            />
          </svg>
        </div>
        <div className="ml-3">
          <p className="text-sm leading-5 text-yellow-700">
            Your account is currently under review. We will reach out if we
            require any further information.
          </p>
        </div>
      </div>
    </div>
  );
};

export default UnderReview;
