import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/Loader";
import API from "../../api/api";
import { useAuth } from "../../contexts/Auth";
import Button from "../../components/Button";
import OnboardingAlert from "./OnboardingAlert";

// temporarily declaring these here. getting the following error when beam-common is installed
// https://github.com/oven-sh/bun/issues/5122
export enum OnboardingStatus {
  Approved = "Approved",
  Pending = "Pending",
  NotStarted = "Not Started",
  Rejected = "Rejected",
}
export type OnboardingProgress = {
  accountId: string;
  name: string;
  type: "Business" | "Individual";
  terms: {
    status: OnboardingStatus | string;
  };
  payment: {
    status: OnboardingStatus | string;
  };
  kyc: {
    status: OnboardingStatus | string;
  };
  // for individuals only
  walletAddress?: {
    status: OnboardingStatus | string;
  };
};
export default function Onboarding() {
  const [loading, setLoading] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const auth = useAuth();
  const navigate = useNavigate();
  const [onboardingList, setOnboardingList] = useState<OnboardingProgress[]>(
    [],
  );

  // we are limiting each acct to 1 business for now
  const businessOnboardingProgress = onboardingList.filter(
    (item) => item.type === "Business",
  )[0];
  const businessKycStatus = businessOnboardingProgress?.kyc?.status;

  // we need to look to see if the logged in user has started an onboaridng, could be personal or business
  const getExistingOnboarding = async () => {
    setLoading(true);
    const { data } = await API.get(
      `business/users/${auth.currentUser?.id}/onboarding`,
    );
    // filter only business
    const businessOnboarding = data.filter(
      (item: OnboardingProgress) => item.type === "Business",
    );
    setOnboardingList(businessOnboarding);
    // if the user has selected a business account, we should show that
    if (auth.currentUser?.selectedAccount) {
      const selectedAccount = businessOnboarding.find(
        (item: OnboardingProgress) =>
          item.accountId === auth.currentUser?.selectedAccount?.accountId,
      );
      if (selectedAccount) {
        navigate(`/accounts/${selectedAccount.accountId}`);
      }
    }
    setLoading(false);
  };
  const startNewOnboarding = async () => {
    setLoading(true);
    // we need to create the new f account for the business and tie this user to it
    // get back the id and navigate to a page to onboard with it
    const { data } = await API.post(
      `business/users/${auth.currentUser?.id}/onboarding`,
      { isBiz: true },
    );
    auth.updateUser({ ...auth.currentUser, selectedAccount: data });
    setLoading(false);
    navigate(`/accounts/${data.accountId}`);
  };

  const handleSubmitOnboarding = async () => {
    setOpenAlert(true);
  };

  useEffect(() => {
    getExistingOnboarding();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const viewOnboardingDetails = (accountId: string) => {
    const name = businessOnboardingProgress?.name;
    const status = businessKycStatus;
    navigate(`/accounts/${accountId}`, {
      state: { status, name, id: accountId },
    });
  };

  return (
    <>
      <OnboardingAlert
        open={openAlert}
        setOpen={setOpenAlert}
        onAccept={startNewOnboarding}
      />
      <div className="grid grid-cols-2 gap-2">
        <div className="flex justify-between items-center p-6 h-auto col-span-2">
          <h3 className="text-base font-semibold leading-6 text-gray-900">
            Accounts
          </h3>
          {!loading && businessKycStatus !== OnboardingStatus.Approved && (
            <Button
              text={`${
                !!businessOnboardingProgress ? "Continue" : "Start"
              } Onboarding`}
              loading={loading}
              disabled={
                loading || businessKycStatus === OnboardingStatus.Approved
              }
              onSubmit={
                !!businessOnboardingProgress
                  ? () =>
                      viewOnboardingDetails(
                        businessOnboardingProgress.accountId,
                      )
                  : handleSubmitOnboarding
              }
            ></Button>
          )}
        </div>
        <div className="px-4 sm:px-6 lg:px-8 col-span-2">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto"></div>
            <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none"></div>
          </div>
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 mt-4">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Terms
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Bank Connected
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      KYB
                    </th>
                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-0"
                    >
                      <span className="sr-only">View</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {onboardingList.map((businessOnboardingProgress) => (
                    <tr key={businessOnboardingProgress.accountId}>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {businessOnboardingProgress.name}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {businessOnboardingProgress.terms.status}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {businessOnboardingProgress.payment.status}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4  text-sm text-gray-500">
                        {businessOnboardingProgress.kyc.status}
                      </td>
                      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                        <button
                          className="text-lavender-700 hover:text-lavender-700"
                          onClick={() =>
                            viewOnboardingDetails(
                              businessOnboardingProgress.accountId,
                            )
                          }
                        >
                          View<span className="sr-only"></span>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {loading && <Loader />}
      </div>
    </>
  );
}
